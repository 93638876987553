import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "@/store/modules/user";

const store = createStore({
    modules: {
        user: user,
    },
    plugins: [createPersistedState()],
    state: {},
    mutations: {},
    actions: {},
});

export { store };
