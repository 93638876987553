import "./bootstrap";
import "../css/app.css";
import "../css/modal.css";
import { store } from "./store"; // Vuex ストアのインポート
import VTooltip from "floating-vue";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
// トースト通知の設定
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        app.use(plugin).use(ZiggyVue, Ziggy).use(store);
        // ここでfloating-vueを設定
        app.directive("tooltip", VTooltip);
        // カスタムテーマの設定（オプション）
        app.provide("VTooltip", {
            themes: {},
        });

        if (props.initialPage.props.auth.user) {
            store.dispatch("fetchUser", props.initialPage.props.auth.user);
        }

        app.use(Toast, {
            position: POSITION.TOP_RIGHT, // トーストの表示位置
            timeout: 3000, // 表示時間
        });

        app.mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
